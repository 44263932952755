

















































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import CollapseTransition from '@/components/CollapseTransition/index.vue';
import { GetPromiseReturn } from '@/api/type/tool';
import { getCouponUsableList } from '@/api/user/coupon';
import { CouponStateEnum } from '@/api/type/base';
type IData = GetPromiseReturn<typeof getCouponUsableList>['payload'][number];

@Component({
  name: '',
  components: { CollapseTransition }
})
export default class extends Vue {
  isShowDetail = false;

  get couponStateEnum() {
    return CouponStateEnum;
  }

  get tips() {
    const useRule = this.innerData.useRule;
    const useScope = this.innerData.useScope;
    if (useRule.discountType !== 3) {
      const isAll = useScope.detailList.some(item => {
        return item.scopeType === -1;
      });
      return isAll ? '所有商品可用' : '部分商品可用';
    } else {
      return '指定商品可用';
    }
  }

  @Prop({ required: true })
  innerData!: IData;

  @Emit('click')
  handleClickUseCoupon() {
    return this.innerData;
  }
}
