








import { getCouponList } from '@/api/user';
import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/Base/BaseList/index.vue';
import CouponItem from './comm/CouponItem.vue';
import CouponUsePopup from './comm/CouponUsePopup.vue';
import { GetPromiseReturn } from '@/api/type/tool';
import { getCouponUsableList } from '@/api/user/coupon';
import { getCouponInfo } from '@/api/product';
import { jumpConfirmCoupon } from '@/utils/jump';
import { ProductTypeEnum } from '@/api/type/base';

type IData = GetPromiseReturn<typeof getCouponUsableList>['payload'][number];
type ICouponData = GetPromiseReturn<typeof getCouponInfo>['payload'];
@Component({
  name: 'BaseCoupon',
  components: { CouponItem, BaseList, CouponUsePopup }
})
export default class extends Vue {
  isShowUseShow = false;

  couponData = {} as ICouponData & Pick<IData, 'defineName' | 'couponNumber' | 'couponPassword'>;

  get getCouponList() {
    return getCouponList;
  }

  async handleClickUseCoupon(data: IData) {
    if (data.useRule.discountType === 3) {
      const { payload } = await getCouponInfo({ couponNumber: data.couponNumber, couponPassword: data.couponPassword });
      if (payload.productSpu.inputRules && ProductTypeEnum.PRODUCT_TYPE_VIRTUAL === payload.productSpu.type) {
        const inputRules = JSON.parse(payload.productSpu.inputRules);
        if (inputRules.rules.length) {
          this.couponData = {
            ...payload,
            couponNumber: data.couponNumber,
            couponPassword: data.couponPassword,
            defineName: data.defineName
          };
          this.isShowUseShow = true;
          return;
        }
      }
      jumpConfirmCoupon({
        skuInfo: [{ skuId: payload.skuId, amount: 1 }],
        productType: payload.productSpu.type,
        couponData: {
          couponNumber: data.couponNumber,
          couponPassword: data.couponPassword
        }
      });
    } else {
      this.$router.push({ name: 'SearchDiscount', params: { id: String(data.defineId) } });
    }
  }
}
