





import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  transitionStyle = '0.3s height ease-in-out';
  beforeEnter(Element: Element) {
    const el = Element as HTMLElement;
    el.style.transition = this.transitionStyle;
    el.style.height = '0';
  }

  enter(Element: Element) {
    const el = Element as HTMLElement;
    if (el.scrollHeight !== 0) {
      el.style.height = `${el.scrollHeight}px`;
    } else {
      el.style.height = '';
    }
    el.style.overflow = 'hidden';
  }

  afterEnter(Element: Element) {
    const el = Element as HTMLElement;
    el.style.transition = '';
    el.style.height = '';
  }

  beforeLeave(Element: Element) {
    const el = Element as HTMLElement;
    el.style.height = `${el.scrollHeight}px`;
    el.style.overflow = 'hidden';
  }

  leave(Element: Element) {
    const el = Element as HTMLElement;
    if (el.scrollHeight !== 0) {
      el.style.transition = this.transitionStyle;
      el.style.height = '0';
    }
  }

  afterLeave(Element: Element) {
    const el = Element as HTMLElement;
    el.style.transition = '';
    el.style.height = '';
  }
}
