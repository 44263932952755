



















import { AddressItem, getDefaultAddress } from '@/api/user/address';
import { jumpConfirmCoupon } from '@/utils/jump';
import { getBuyAddress, setBuyAddress } from '@/utils/storage';
import { AddressOptionsEnum } from '@/views/address/address-list.vue';
import { createOrderFromItemType } from '@/views/product/comm/VirtualProductMain.vue';
import { isEmpty } from 'lodash';
import { Toast } from 'vant';
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import couponVue from '../coupon.vue';
@Component({
  name: ''
})
export default class extends Vue {
  @PropSync('show')
  innerShow!: boolean;

  @Prop()
  innerData!: couponVue['couponData'];
  addressInfo = {} as AddressItem;

  form: Record<string, any> = {};

  /* 虚拟商品输入列表 */
  get formList(): createOrderFromItemType[] {
    const inputRules = JSON.parse(this.innerData.productSpu.inputRules || '[]');
    if (inputRules) {
      return inputRules.rules;
    }
    return [];
  }

  async getDefaultAddress() {
    const address = getBuyAddress();

    if (!isEmpty(address) && address) {
      this.addressInfo = address;
    } else {
      const { payload } = await getDefaultAddress();
      setBuyAddress(payload || {});
      this.addressInfo = payload || {};
    }
  }

  validate(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.formList.forEach((item, index) => {
        if (!this.form[index]) {
          reject();
          Toast('请输入' + item.name);
          return;
        }
        if (item.pattern) {
          const reg = new RegExp(item.pattern);
          if (!reg.test(this.form[index])) {
            reject();
            Toast(item.name + '格式错误');
            return;
          }
        }
      });
      resolve();
    });
  }

  handleClickCancel() {
    this.innerShow = false;
  }
  async handleClickConfirm() {
    await this.validate();
    jumpConfirmCoupon({
      skuInfo: [{ skuId: this.innerData.skuId, amount: 1 }],
      productType: this.innerData.productSpu.type,
      account: this.formList.map((item, index) => ({
        key: item.name,
        value: this.form[index]
      })),
      couponData: {
        couponNumber: this.innerData.couponNumber,
        couponPassword: this.innerData.couponPassword
      }
    });
  }

  /* 去新增地址 */
  goToAddressAdd() {
    this.$router.push({
      name: 'AddressEdit',
      query: {
        type: AddressOptionsEnum.CREATE_ORDER_SELECT
      }
    });
  }

  onOpen() {
    this.getDefaultAddress();
  }
}
